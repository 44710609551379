export const charByLevel = (wordCount) => {
  const level = wordCount / 10
  if (level === 0 || level === 1) {
    return {
      name: '金の卵',
      src: 'golden_egg.png'
    }
  }
  if (level === 2 || level === 3) {
    return {
      name: 'ひよこ',
      src: 'hiyoko.png'
    }
  }
  if (level === 4 || level === 5) {
    return {
      name: 'にわとり',
      src: 'animal_stand_niwatori.png'
    }
  }
  if (level === 6 || level === 7) {
    return {
      name: 'だちょう',
      src: 'animal_dachou.png'
    }
  }
  if (level >= 8) {
    return {
      name: 'ドラゴン',
      src: 'fantasy_dragon.png'
    }
  }
  return {
    name: '金の卵',
    src: 'golden_egg.png'
  }
}
import React from "react";
import Pusher from "pusher-js";
import $ from 'jquery';

function useCtrl(Proto, fun) {
  const [ctrl] = React.useState(() => new Proto());
  React.useEffect(() => void ctrl.effect());
  React.useEffect(() => () => ctrl.clean(), [ctrl]);
  return ctrl.apply(fun);
}

export const usePusher = (fun = () => {}) => useCtrl(PusherController, fun)

export const useSpeech = (fun = () => {}) => useCtrl(SpeechController, fun);

export class PusherController {
  fun = () => {}

  apply(fun = () => {}) {
    this.fun = fun
    return this.push.bind(this);
  }

  push(data) {
      $.ajax({
        type: "POST",
        url: "https://glacial-coast-18663.herokuapp.com/pusher/effort",
        data: data,
        dataType: 'json'
      })
      .done((response) => {
        // console.log(response);
        if (response.status === "err") {
            alert("err: " + response.msg);
        } else {
            alert("OK");
        }
      });
  }

  effect() {
    Pusher.logToConsole = true;
    this.pusher = new Pusher("85a37ec9e25e0248c92f", {
      cluster: "ap3",
      forceTLS: true,
    });
    this.channel = this.pusher.subscribe("my-channel");
    this.channel.bind("effort-event", this.fun);
  }
  clean() {}
}

export class SpeechController {
  lastSplitedTranscipt = [];
  wordQueue = [];
  wordCount = 0;

  fun = () => {};

  apply = (fun = () => {}) => void (this.fun = fun);

  effect() {
    const Recognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new Recognition();
    recognition.continuous = true;
    recognition.lang = 'en-US';
    recognition.interimResults = true;
    recognition.maxAlternatives = 0;
    recognition.onresult = (event) => void this.onresult(event);
    recognition.onend = () => void recognition.start();
    recognition.start();
  }

  clean() {}

  onresult(event) {
    // ハッカソンのために注目すべきSpeechRecognitionAlternativeオブジェクト
    let targetSpeechRecognitionAlternative = this.findSpeechRecognitionAlternative(event);
    // let effort;
    if (targetSpeechRecognitionAlternative != null) {
      this.updateWordQueue(targetSpeechRecognitionAlternative);
      this.pushIfNeeded(); // wordListに単語がたまってたら送信
    }
  }

  /**
   * return speechRecognitionAlternative | null
   */
  findSpeechRecognitionAlternative(event) {
    for (let i = 0; i < event.results.length; i++) {
      if (!event.results[i].isFinal) {
        let speechRecognitionResult = event.results[i];

        for (let j = 0; j < speechRecognitionResult.length; j++) {
          let speechRecognitionAlternative = speechRecognitionResult[j];

          if (speechRecognitionAlternative.confidence > 0.8) {
            return speechRecognitionAlternative;
          }
        }
      }
    }
    return null;
  }

  updateWordQueue(targetSpeechRecognitionAlternative) {
    const splitedTranscript = targetSpeechRecognitionAlternative.transcript.split(' '); // string[]
    // 比較して短くなってたら新しい文章が始まったと判断する
    // const addedWordsCount = splitedTranscript.length - this.lastSplitedTranscipt.length;
    if (splitedTranscript.length > 0) {
      for (let i = this.lastSplitedTranscipt.length; i < splitedTranscript.length; i++) {
        this.wordQueue.push(splitedTranscript[i])
      }
    }
    this.lastSplitedTranscipt = splitedTranscript;
  }

  pushIfNeeded() {
    if (this.wordQueue.length >= 10) {
      this.wordCount += this.wordQueue.length;
      let effort = {
        'session_id': this.pusher.sessionID,
        'word_list': this.wordQueue,
        'word_count': this.wordCount
      }
      this.fun(effort);
      this.wordQueue = [];
    }
  }
}
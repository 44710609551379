import { useState } from "react"
import { usePusher, useSpeech } from "./hooks";
import { charByLevel } from './utils';

function User(props) {
  const { sessionId, wordCount=0, wordList=[] } = props;
  const level = Math.sqrt(wordCount);
  return (
      <tr>
        <td>
          <img width="100px" {...charByLevel(level)} />
          <div style={{ display: "flex", flexDirection: "column"}}>
            <td>名前1</td>
            { sessionId }
            <td><h2 style={{ margin: 0 }}>Lv.2</h2></td>
            { level }
          </div>
        </td>
        <td style={{ maxWidth: "80vh", border: "1px solid", padding: "5px", fontSize: "1.7vh", textAlign: "left"}}>
          { wordList.length? wordList: "Lets talking" }
        </td>
      </tr>
  )
}

function App() {
  const [users, setUsers] = useState([]);
  const [effort, setEffort] = useState({wordCount: 0, wordList: []})

  const push = usePusher((data) => {
    setUsers(p => p.map(user => user.sessionId !== data.sessionID? user: data));
  });

  useSpeech((data) => {
    setEffort(data);
    push(data);
  });

  return (
    <div className="App">
      <div style={{ textAlign: "center"}}>
        <h3 style={{ fontSize: "10vh" }}>Effort Monster</h3>
        <div style={{ display: " flex", flexDirection: "column", height: "40vh", minWidth: "100vh",alignItems: "center" }}>
          <table>
            {[effort, ...users].map((user, key) => (
              <User {...user} key={key} />
            ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default App;
